<template>
    <div>
        <Quote title="Bu yerda siz ro'yxatdan o'tishingiz mumkin"/>
        <GoBack next="Ro'yxatdan o'tish"/>
        <div class="row justify-content-center">
            <div class="col-11 col-sm-11 col-md-8">
                <form @submit.prevent="registration" class="ABC">
                    <!--  Email input -->
                    <div class="input mb-4">
                        <label-component
                            label-for="exampleInputEmail1"
                            class="mb-1"
                        >
                            E-mailni kiriting
                            <span class="span">
                                <strong v-if="!forms.email" class="invalidMessage text-danger">&#x2736;</strong>
                                <strong v-else-if="isValidEmail" class="text-success">&#10003;</strong>
                            </span>
                        </label-component>
                        <input-component
                            model-name="email"
                            type="email"
                            id="exampleInputEmail1"
                            :model-form="forms"
                            required
                        />
                        <div class="error text-danger" style="font-size: 15px">
                            <p v-if="!isValidEmail && forms.email">
                                Email <strong>@</strong> va <strong>.</strong> dan iborat bo'lishi shart
                            </p>
                        </div>
                    </div>

                    <!--  Password input -->
                    <div class="input-container">
                        <div class="input mb-0">
                            <label-component
                                class="mb-1"
                                labelFor="exampleInputPassword1"
                            >
                                Parolni kiriting
                                <strong v-if="!forms.password" class="invalidMessage text-danger">&#x2736;</strong>
                                <strong v-else-if="forms.password.length >= 6" class="text-success">&#10003;</strong>
                            </label-component>
                            <input-component
                                model-name="password"
                                :type="showPassword ? 'text' : 'password'"
                                id="exampleInputPassword1"
                                :model-form="forms"
                                required
                            />
                            <div class="eye-icon" @click="toggleShowPassword">
                                <i :class="['fa', showPassword ? 'fa-eye-slash' : 'fa-eye']"></i>
                            </div>
                        </div>
                    </div>
                    <div class="error text-danger" style="font-size: 15px">
                        <p v-if="forms.password.length < 6 && forms.password">
                            Parol kamida 6ta belgidan iborat bo'lishi kerak
                        </p>
                    </div>

                    <!--  RePassword input -->
                    <div class="input-container">
                        <div class="input mb-0 mt-4">
                            <label-component
                                label-for="exampleInputPassword2"
                                class="mb-1"
                            >
                                Parolni qaytaring
                                <strong v-if="!forms.rePassword" class="invalidMessage text-danger">&#x2736;</strong>
                                <strong v-else-if="forms.rePassword === forms.password" class="text-success">
                                    &#10003;
                                </strong>
                            </label-component>
                            <input-component
                                model-name="rePassword"
                                :type="showRePassword ? 'text' : 'password'"
                                id="exampleInputPassword2"
                                :model-form="forms"
                                required
                            />
                            <div class="eye-icon" @click="toggleShowRePassword">
                                <i :class="['fa', showRePassword ? 'fa-eye-slash' : 'fa-eye']"></i>
                            </div>
                        </div>
                    </div>
                    <div class="error text-danger" style="font-size: 15px">
                        <p v-if="forms.rePassword !== forms.password && forms.rePassword">
                            Iltimos, to'g'ri parol kiriting
                        </p>
                    </div>

                    <!--  GivenName input -->
                    <div class="input mb-4 mt-4">
                        <label-component
                            label-for="exampleInputGivenName1"
                            class="mb-1"
                        >
                            Ismingizni kiriting
                            <strong v-if="!forms.givenName" class="invalidMessage text-danger">&#x2736;</strong>
                            <strong v-else-if="isValidGivenName && forms.givenName.length >= 3" class="text-success">
                                &#10003;
                            </strong>
                        </label-component>
                        <input-component
                            model-name="givenName"
                            type="text"
                            id="exampleInputGivenName1"
                            :model-form="forms"
                            required
                        />
                        <div class="error text-danger" style="font-size: 15px">
                            <p v-if="!isValidGivenName && forms.givenName">
                                Ism faqat harflardan iborat bo'lishi kerak
                            </p>
                            <p v-else-if="forms.givenName.length < 3 && forms.givenName">
                                Ism kamida 3ta harfdan iborat bo'lishi kerak
                            </p>
                        </div>
                    </div>

                    <!--  FamilyName input -->
                    <div class="input mb-4">
                        <label-component
                            for="exampleInputFamilyName1"
                            class="mb-1"
                        >
                            Familiyangizni kiriting
                            <strong v-if="!forms.familyName" class="invalidMessage text-danger">&#x2736;</strong>
                            <strong v-else-if="isValidFamilyName && forms.familyName.length >= 3" class="text-success">
                                &#10003;
                            </strong>
                        </label-component>
                        <input-component
                            model-name="familyName"
                            :model-form="forms"
                            type="text"
                            required
                            id="exampleInputFamilyId1"
                        />
                        <div class="error text-danger" style="font-size: 15px">
                            <p v-if="!isValidFamilyName && forms.familyName">
                                Familiya faqat harflardan iborat bo'lishi kerak
                            </p>
                            <p v-else-if="forms.familyName.length < 3 && forms.familyName">
                                Familiya kamida 3ta harfdan iborat bo'lishi kerak
                            </p>
                        </div>
                    </div>

                    <!--  Phone input -->
                    <div class="input mb-4">
                        <label-component
                            for="tel"
                            class="mb-1"
                        >
                            Bog'lanish uchun telefon raqam kiriting
                            <strong v-if="!forms.phoneNumber" class="invalidMessage text-danger">&#x2736;</strong>
                            <strong v-else-if="forms.phoneNumber.length >= 11 && isValidPhoneNumber"
                                    class="text-success">
                                &#10003;
                            </strong>
                        </label-component>
                        <vue-tel-input
                            id="tel"
                            type="tel"
                            :inputOptions="options"
                            v-model="forms.phoneNumber"
                            @input="phoneNumberInput"
                            :preferredCountries="['UZ', 'RU', 'KR', 'JP']"
                            class="tel mt-0"
                        >
                        </vue-tel-input>
                        <div class="error text-danger" style="font-size: 15px">
                            <p v-if="!isValidPhoneNumber && forms.phoneNumber">
                                Telefon raqam faqat + va raqamalrdan iborat bo'lishi kerak
                            </p>
                        </div>
                    </div>

                    <!--  Telegram Login input -->
                    <div class="input mb-4">
                        <label-component
                            for="exampleInputId1" class="mb-1"
                        >
                            Telegram loginni kiriting
                            <strong v-if="!forms.telegramNumber" class="invalidMessage text-danger">&#x2736;</strong>
                            <strong
                                v-else-if="forms.telegramNumber && forms.telegramNumber.length >= 2"
                                class="text-success"
                            >
                                &#10003;
                            </strong>
                        </label-component>
                        <input-component
                            model-name="telegramNumber"
                            :model-form="forms"
                            required
                            id="exampleInputId1"
                        />
                        <div class="error text-danger" style="font-size: 15px">
                            <p v-if="!isValidTelegramLogin && forms.telegramNumber">
                                Telegram <strong>@</strong> bilan boshlanishi kerak
                            </p>
                            <p v-else-if="forms.telegramNumber > 2 && forms.telegramNumber">
                                Telegram kamida ikkita belgidan iborat bo'lishi kerak
                            </p>
                        </div>
                    </div>

                    <!--  Public offer -->
                    <div class="d-flex justify-content-start input mb-3">
                        <div>
                            <input type="checkbox" id="offer" class="me-2" required>
                        </div>
                        <div>
                            <router-link
                                to="/public-offer"
                                target="_blank"
                                style="text-decoration: none;
                                color: #765AF8"
                            >
                                Ommaviy ofertani
                            </router-link>
                            <label for="offer" style="cursor: pointer;">qabul qilaman</label>
                        </div>
                    </div>

                    <div class="d-block d-md-flex justify-content-between align-items-center">
                        <div
                            class="d-none d-md-flex justify-content-left mr-2"
                            style="font-size: 15px"
                            @click="scrollToTop()"
                        >
                            <p class="mt-4 mt-md-0">
                                Agar allaqachon ro'yxatdan o'tgan bo'lsangiz, tizimga
                                <router-link to="/login" class="hover-decoration">kiring.</router-link>
                            </p>
                        </div>
                        <div class="row d-none d-md-flex justify-content-left col-12 col-md-5 col-lg-4">
                            <button-component :class="'btn btn-primary'" :disabled="isDisabled">
                                Ro'yxatdan o'tish
                            </button-component>
                        </div>

                        <!-- ekran md da button col-12 bo`ladi-->
                        <div class="row d-flex d-md-none justify-content-left col-12 col-md-5 col-lg-4">
                            <button-component :class="'btn btn-primary'" :disabled="isDisabled">
                                Ro'yxatdan o'tish
                            </button-component>
                        </div>
                        <div
                            class="d-flex d-md-none justify-content-left"
                            style="font-size: 15px"
                            @click="scrollToTop()"
                        >
                            <p class="mt-4 mt-md-0">
                                Agar allaqachon ro'yxatdan o'tgan bo'lsangiz, tizimga
                                <router-link to="/login" class="hover-decoration">kiring.</router-link>
                            </p>
                        </div>
                    </div>
                    <div>
                        <b-modal ref="modal-unsuccess" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Siz ro'yxatdan o'tgan email kiritdingiz. Iltimos boshqa email kiriting.</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="errorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                    <div>
                        <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Siz muvaffaqiyatli ro'yxatdan o'tdingiz!</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import GoBack from "@/components/GoBack";
import Quote from "@/components/Quote";
import {email, helpers, minLength, required, sameAs} from 'vuelidate/lib/validators'
import {mapActions} from "vuex";
import ButtonComponent from "@/components/ButtonComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import InputComponent from "@/components/InputComponent.vue";

const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[A-Za-z]+$/);
const alphaTelegramLogin = helpers.regex('alphaTelegramLoginAndDot', /^@/);

export default {
    name: "RegistrationPage",
    components: {LabelComponent, InputComponent, ButtonComponent, GoBack, Quote},
    methods: {
        ...mapActions(['fetchPushUser', 'fetchToken']),
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        registration() {
            this.fetchPushUser(this.forms)
                .then(() => {
                    this.$refs['modal-success'].show()
                    setTimeout(this.hideErrorModal, 5000)
                    this.login()
                })
                .catch(() => {
                    this.$refs['modal-unsuccess'].show()
                    setTimeout(this.errorModal, 5000)
                    console.log('registration da xato')
                })
                .finally(() => {

                })
        },
        login() {
            this.fetchToken({email: this.forms.email, password: this.forms.password})
                .then(() => {
                    this.$router.push('/')
                })
                .catch(() => {
                    console.log('loginda token olishda xato')
                })
        },
        phoneNumberInput(value) {
            if (value && value.length > 0 && value.indexOf('+') !== 0) {
                this.forms.phoneNumber = '+' + value
            }
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
        },
        errorModal() {
            this.$refs['modal-unsuccess'].hide()
        },
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
        },
        toggleShowRePassword() {
            this.showRePassword = !this.showRePassword;
        },
    },
    computed: {
        isValidGivenName() {
            const regex = /^[A-Za-z/'/`]*$/
            return regex.test(this.forms.givenName)
        },
        isValidFamilyName() {
            const regex = /^[A-Za-z/'/`]+$/
            return regex.test(this.forms.familyName)
        },
        isValidTelegramLogin() {
            const regex = /^@/
            return regex.test(this.forms.telegramNumber)
        },
        isValidEmail() {
            const regex = /^[-_\w.]+@[-_\w.]+\.[-_\w.]+$/
            return regex.test(this.forms.email)
        },
        isValidPhoneNumber() {
            const regex = /^\+[0-9\s]+$/
            return regex.test(this.forms.phoneNumber)
        },
        isDisabled() {
            return this.isValidEmail === false ||
                this.forms.password.length < 6 ||
                this.forms.password !== this.forms.rePassword ||
                this.isValidGivenName === false || this.forms.givenName.length < 3 ||
                this.isValidFamilyName === false || this.forms.familyName.length < 3 ||
                this.isValidPhoneNumber === false || this.forms.phoneNumber.length < 11 ||
                this.isValidTelegramLogin === false || this.forms.telegramNumber.length < 2
        }
    },
    data() {
        return {
            rePassword: "",
            forms: {
                email: "",
                password: "",
                givenName: "",
                familyName: "",
                phoneNumber: "",
                telegramNumber: "",
                roles: ["ROLE_USER"]
            },
            showPassword: false,
            showRePassword: false,
            options: {placeholder: ""},
        }
    },
    validations: {
        rePassword: {
            required,
            sameAs: sameAs(vm => {
                return vm.forms.password
            })
        },
        forms: {
            email: {required, email},
            password: {required, minLength: minLength(6)},
            givenName: {required, minLength: minLength(3), helpers: alphaNumAndDotValidator},
            familyName: {required, minLength: minLength(3), helpers: alphaNumAndDotValidator},
            phoneNumber: {required},
            telegramNumber: {required, helpers: alphaTelegramLogin},
        },
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap');

* {
    transition: all 0.4s;
    padding: 0;
    margin: 0;
}

section form {
    font-family: 'Comfortaa', cursive;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
}

.input .form-control {
    border-radius: 10px !important;
    border: solid #80007F 1px !important;
    font-family: Comfortaa, cursive !important;
}

#tel {
    width: 100% !important;
    height: 38px !important;
    margin-top: 32px;
    margin-bottom: 10px;
    border-radius: 10px !important;
    border: solid #80007F 1px !important;
    font-family: Comfortaa, cursive !important;
    padding-right: 15px !important;
    padding-left: 12px !important;
    outline: 0 none !important;
    border-image-outset: 0 !important;
}

.btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    background-color: #80007F !important;
    border-color: #80007F;
    outline: none !important;
    color: #fff;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: none !important;
}

.btn-primary:hover {
    background-color: #990098 !important;
    border-color: #990098;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
#tel:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control {
    color: #000000;
    padding-left: 1.5rem;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.ABC {
    margin-top: 100px;
    margin-bottom: 100px;
}

.eye-icon {
    position: absolute;
    top: 70%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
}

.input-container {
    position: relative;
}
</style>
